<template>
  <section class="contact" id="contact">
    <div class="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d817.7534186752529!2d-59.9786585303597!3d-34.93119399881062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bea569fbd8dbb1%3A0x1d4e9d2e7df2ca3d!2sTGPLAST%20SRL!5e0!3m2!1ses!2sar!4v1728270860412!5m2!1ses!2sar"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <h2 class="title mt-4">Contacto</h2>
    <div class="px-4">
      <div class="columns px-1">
        <div class="column">
          <cardContact
            text="Teléfono fijo"
            value="2346421074"
            link="tel:+2346421074"
            description="Contáctanos directamente por teléfono para asistencia inmediata."
            icon="fas fa-phone"
          />
        </div>
        <div class="column">
          <cardContact
            text="Email"
            value="ventas@tgplastsrl.com"
            link="mailto:ventas@tgplastsrl.com"
            description="Envía tus consultas por correo electrónico y te responderemos a la brevedad."
            icon="fas fa-envelope"
          />
        </div>
        <div class="column">
          <cardContact
            text="WhatsApp"
            value=" 2346-337088"
            link="https://wa.me/2346337088?text=Hola,%20me%20gustaría%20obtener%20más%20información%20sobre%20sus%20servicios."
            description="Comunícate con nosotros de manera rápida y sencilla por WhatsApp."
            icon="fab fa-whatsapp"
          />
        </div>
        <div class="column">
          <cardContact
            text="Instagram"
            value="_tgplast"
            link="https://www.instagram.com/_tgplast"
            description="Síguenos en Instagram para conocer nuestras últimas novedades y productos."
            icon="fab fa-instagram"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import cardContact from "@/components/CardContact.vue";
export default {
  components: {
    cardContact,
  },
};
</script>

<style lang="scss" scoped>
.contact {
  background-color: $orange;
  padding-bottom: 4em;
  padding-top: 0 !important;

  .title {
    color: $white;
    text-align: center;
  }

  .map {
    iframe {
      width: 100%;
      height: 450px;
      border-radius: 0px;
    }
  }

  .contact-info {
    font-size: 20px;
    .fa-phone {
      fill: $white !important;
    }
  }

  a {
    color: $white !important;

    &:hover {
      color: $white;
    }
  }
}
</style>