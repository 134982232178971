<template>
  <div>
    <section class="container category">
      <b-button
        class="my-3"
        type="is-primary"
        size="is-small"
        icon-left="arrow-left"
        @click="$router.go(-1)"
      >
        Atrás
      </b-button>
      <article>
        <h1 class="title">Polipropileno PP</h1>
        <p>
          Es un polímero termoplástico utilizado en una amplia variedad de
          aplicaciones y uno de los motivos es que es extraordinariamente
          resistente a muchos solventes químicos, bases y ácidos. Su clasificación
          como elemento reciclable lleva por símbolo un triangulo, dentro un 5 y
          debajo las letras PP.
        </p>
        <br />
        <p>
          El polipropileno puede ser moldeado con la calefacción solamente, es
          decir, es un termoplástico. Tiene propiedades similares al polietileno
          (PE), pero con un punto de ablandamiento más alto.
        </p>
        <br />
      </article>
      <b-tabs class="mt-5" v-model="activeTab">
        <b-tab-item label="Características">
          <ul class="custom-ul">
            <li>De bajo costo.</li>
            <li>Alta resistencia química a los disolventes.</li>
            <li>Fácil de moldeo.</li>
            <li>Alta resistencia a la fractura por flexión o fatiga.</li>
            <li>
              Buena resistencia al impacto superior a temperaturas superiores a
              los 15 ° C.
            </li>
            <li>Buena estabilidad térmica.</li>
            <li>
              Aumento de la sensibilidad a la luz UV y agentes oxidantes,
              sufriendo a la degradación más fácilmente.
            </li>
            <li>Rango de temperatura de trabajo 0ºC +100ºC.</li>
            <li>Posee una gran capacidad de recuperación elástica.</li>
            <li>
              Resiste al agua hirviente, pudiendo esterilizarse a temperaturas de
              140ºC sin deformación.
            </li>
            <li>
              Resiste a las aplicaciones de carga en un ambiente a una temperatura
              de 70ºC sin producir deformación.
            </li>
            <li>Gran resistencia a la penetración de los microorganismos.</li>
            <li>
              Gran resistencia a los detergentes comerciales a una temperatura de
              80ºC.
            </li>
            <li>Debido a su densidad flota en el agua.</li>
          </ul>
        </b-tab-item>
        <b-tab-item label="Ventajas y desventajas">
          <p>
            <span>Ventajas:</span> es barato, utilizable en la industria
            alimentaria (es inodoro y no tóxico), muy resistente a la fatiga y
            flexión, muy denso, químicamente inerte, esterilizable y reciclable.
            Es un excelente aislante eléctrico.
          </p>
          <p>
            <span>Desventajas:</span> es frágil a baja temperatura, sensible a los
            rayos UV, menos resistente a la oxidación que el polietileno y difícil
            de pegar.
          </p>
        </b-tab-item>

        <b-tab-item label="Aplicaciones">
          <ul class="custom-ul">
            <li>Pilones</li>
            <li>Bujes</li>
            <li>Roldanas</li>
            <li>Ruedas</li>
            <li>Guías</li>
            <li>Perfiles</li>
            <li>Rodillos</li>
            <li>Cojinetes</li>
            <li>Topes</li>
            <li>Arandelas</li>
          </ul>
        </b-tab-item>

        <b-tab-item label="Medidas">
          <ul class="custom-ul">
            <span>Barras redondas:</span>
            <ul>
              <li>Desde Ø25 mm a 150 mm x 1000 mm de largo.</li>
              <li>
                <span>Largo:</span> lo estándar es 1000 mm pero se puede fabricar
                a pedido largos especiales.
              </li>
            </ul>
            <p class="my-4">Barras cuadradas:</p>
            <ul>
              <li>Desde 20 a 80 mm x 1000 mm de largo.</li>
              <li>
                <span>Largo:</span> lo estándar es 1000 mm pero se puede fabricar
                a pedido largos especiales.
              </li>
            </ul>
            <p class="my-4">Tubos o bujes: Consultar.</p>
            <p class="my-4">Planchas:</p>
            <ul>
              <li><span>Espesores:</span> 6-8-10-12-15-20-25-30-40-50-60-Etc.</li>
              <li><span>Anchos:</span> 350-400-450-500-600 mm.</li>
              <li>
                <span>Largos:</span> lo estándar es 2000 mm pero se puede fabricar
                a pedido largos especiales.
              </li>
            </ul>
          </ul>
        </b-tab-item>
      </b-tabs>

      <div
        class="columns mt-5 is-justify-content-space-around is-multiline is-centered"
      >
        <CardCategory
          v-for="(card, index) in banners.carousels"
          :key="index"
          :content="card.content"
          :title="card.title"
          :url="card.image"
          class="column is-3 is-four-fifths-mobile m-auto"
        />
      </div>

      <!-- <div class="buttons">
        <div class="block">
          <b-button type="is-danger" icon-left="download" @click="downloadFile">
            Descargar características Polipropileno PP
          </b-button>
        </div>
      </div> -->
    </section>
    <Contact/>
  </div>
</template>

<script>
import CardCategory from "@/components/CardCategory.vue";
import bannersData from "../config/polypropylene.json";
import Contact from "./ContactSection.vue";

export default {
  components: {
    CardCategory,
    Contact
  },
  data() {
    return {
      activeTab: 0,
      isIndex: false,
      banners: [],
    };
  },
  methods: {
    downloadFile() {
      const fileUrl = "/files/polipropileno.pdf";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "polipropileno.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  created() {
    this.banners = bannersData;
  },
};
</script>