<template>
  <div class="card">
    <div class="card-image">
      <figure class="image is-4by3" v-if="url !== ''">
        <img
          :src="getImageUrl(url)"
          :alt="title"
        />
      </figure>
      <figure class="image" v-else>
        <video class="m-auto" controls>
        <source :src="getVideoUrl(video)" type="video/mp4">
          Tu navegador no soporta videos.
        </video>
      </figure>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p class="title is-4">{{ title }}</p>
        </div>
      </div>
      <div class="content">
        {{ content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: { type: String },
    title: { type: String, required: true },
    content: { type: String, required: true },
    video: { type: String },
  },
  methods: {
    getImageUrl(imagePath) {
      return require(`@/assets/img/${imagePath}`);
    },
    getVideoUrl(videoPath) {
      console.log(videoPath)
      return require(`@/assets/img/${videoPath}`);
    },
  }
}
</script>

<style lang="scss" scoped>
.title{
  text-align: left !important;
}

.image{
  video{
    height: 240px !important;
    width: 300px !important;
  }
}
@media (max-width: 768px) {
  .card{
    margin-bottom: 25px !important;
  }
}
</style>