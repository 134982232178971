<template>
<div>
  <section class="container category">
    <b-button
      class="my-3"
      type="is-primary"
      size="is-small"
      icon-left="arrow-left"
      @click="$router.go(-1)"
    >
      Atrás
    </b-button>
    <article>
      <h1 class="title">Recubrimientos plásticos</h1>
      <article class="mechanized-text">
        <p>
          Contamos con termoplásticos especiales utilizados para el
          recubrimiento de <span>CAJAS</span> y <span>BATEAS</span> de camiones
          volcadores logrando una rápida, segura y limpia descarga de los
          materiales.
        </p>
        <p>
          Nuestros plásticos tienes distintos espesores según el requerimiento
          de nuestros clientes, partimos de 2 mm hasta 12 mm o mas según se
          requiera.
        </p>
        <p>
          Tenemos la posibilidad de adaptarnos a cualquier dimensión de cajas o
          bateas ya que también ofrecemos el servicio de instalación de los
          mismos.
        </p>
        <p>
          Por otra parte hemos desarrollado en el rubro
          <span>AGRO</span> distintos tipos de recubrimientos en silos y mixer,
          donde los cereales y/o fertilizantes, se pegan y corroen las chapas
          rápidamente.
        </p>
        <p>
          Nuestra política es mejorar y adaptarnos a las necesidades de los
          usuarios y/o fabricantes y aportar con las propiedades del plástico,
          los beneficios que se puedan lograr para mejorar rendimientos en el
          uso.
        </p>
      </article>
    </article>
    <div
      class="columns mt-5 is-justify-content-space-around is-multiline is-centered"
    >
      <CardCategory
        v-for="(card, index) in banners.carousels"
        :key="index"
        :content="card.content"
        :title="card.title"
        :url="card.image"
        :video="card.video"
        class="column mb-5 mx-2 is-3 is-four-fifths-mobile"
      />
    </div>
  </section>
  <Contact/>
</div>
</template>

<script>
import CardCategory from "@/components/CardCategory.vue";
import bannersData from "../config/plastic.json";
import Contact from "./ContactSection.vue";
export default {
  components: {
    CardCategory,
    Contact
  },
  data() {
    return {
      isIndex: false,
      banners: [],
    };
  },
  created() {
    this.banners = bannersData;
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .column{
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.mechanized-text {
  p {
    span {
      color: $orange;
      font-weight: 800;
    }
  }
}
.video {
  video {
    margin: auto !important;
  }
}
</style>