<template>
  <a :href="link" data-aos="flip-left" class="card custom-card">
    <div class="card-content">
      <div class="media">
        <div class="media-left">
          <span class="icon">
            <i :class="icon"></i>
          </span>
        </div>
        <div class="media-content">
          <p class="title is-4 has-text-primary">{{ text }}</p>
        </div>
      </div>

      <div class="content">
        <p class="p-0 m-0 custom-link">{{ value }}</p>
        <p class="p-0 m-0 custom-description">
          {{ description }}
        </p>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: 'fas fa-user'
    }
  }
}
</script>

<style lang="scss">
.custom-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s ease;
  display: block;
  background-color: $white !important;

  .title {
    font-family: quattrocento-bold, serif;
    font-weight: bold;
    color: $black !important;
    text-align: left !important;
  }

  .icon{
    color: $black;
  }
}

.custom-card:hover {
  transform: scale(1.01) !important;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  .title, .custom-description, .icon, .custom-link {
    color: $orange !important;
  }

}

.custom-link {
  font-size: 1.1rem;
  color: $black;
  font-weight: bold;
  text-decoration: none;
}

.custom-description {
  margin-top: 10px;
  color: #4a4a4a;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .custom-card {
    padding: 10px;
  }

  .title {
    font-size: 1.5rem;
  }

  .custom-description {
    font-size: 0.9rem;
  }
}
</style>
