<template>
<div>
  <section class="container category">
    <b-button
      class="my-3"
      type="is-primary"
      size="is-small"
      icon-left="arrow-left"
      @click="$router.go(-1)"
    >
      Atrás
    </b-button>
    <article>
      <h1 class="title">Poliamida 6 PA6</h1>
      <p>
        Termoplástico con estructura semicristalina muy dúctil y duro. Es un
        material de bajo peso específico con gran resistencia a los aceites,
        grasas, disolventes, productos químicos y a la corrosión, por lo que se
        utiliza para la sustitución de metales, madera y cristal en la
        industria.
      </p>
      <br />
      <p>
        Las Poliamidas, además de resistencia, dureza y tenacidad altas, también
        poseen una alta resistencia a la deformación térmica (resistentes a
        temperaturas de -40ºC a aprox. +100ºC). Pero puesto que las buenas
        propiedades mecánicas apenas se alcanzan después de un acondicionamiento
        del material, este deberá volver a acondicionarse después de un
        recocido. Sin embargo, este acondicionamiento también se presentará con
        un almacenaje prolongado al aire.
      </p>
      <br />
      <p>
        La poliamida tiene un alto grado de absorción del agua, por lo tanto se
        deberá tener en cuenta un cambio de dimensión y la influencia en las
        propiedades mecánicas y eléctricas. Se aplica en componentes de
        cojinetes, ruedas dentadas, piezas de bombas, barras de deslizamiento,
        roldanas o guarniciones.
      </p>
      <br />
    </article>
    <b-tabs class="mt-5" v-model="activeTab">
      <b-tab-item label="Características">
        <ul class="custom-ul">
          <li>Alto grado de absorción de agua.</li>
          <li>
            Buenas propiedades de deslizamiento y de resistencia para la marcha
            en seco.
          </li>
          <li>Alta resistencia mecánica incluso a temperaturas elevadas.</li>
          <li>
            Es resistente al choque, tiene una resiliencia alta y un gran poder
            amortiguador.
          </li>
          <li>Gran resistencia a la abrasión.</li>
          <li>Grado de elasticidad elevado.</li>
          <li>Puede ser pegado y soldado.</li>
          <li>
            Buena resistencia a aceites de maquinaria, hidrógeno de carbono,
            disolvente orgánico y carburantes, pero no a los ácidos
            concentrados.
          </li>
        </ul>
      </b-tab-item>
      <b-tab-item label="Aplicaciones">
        <p>Bujes y ruedas industriales</p>
        <ul class="custom-ul">
          <li>Ruedas dentadas.</li>
          <li>
            Piezas plásticas de mucha exigencia mecánica (ej. Maquinas
            agrícolas).
          </li>
          <li>Sinfines</li>
          <li>Cojinetes de fricción</li>
          <li>Guías deslizamiento</li>
          <li>Sustituye a Metales, Madera y Vidrio</li>
        </ul>
      </b-tab-item>

      <b-tab-item label="Medidas">
        <ul class="custom-ul">
          <p class="my-4">Barras redondas:</p>
          <ul>
            <li>Desde Ø25 mm a 150 mm x 1000 mm de largo.</li>
            <li>
              <span>Largo:</span> lo estándar es 1000 mm pero se puede fabricar
              a pedido largos especiales.
            </li>
          </ul>
          <p class="my-4">Barras cuadradas:</p>
          <ul>
            <li>Desde 20 a 80 mm x 1000 mm de largo.</li>
            <li>
              <span>Largo:</span> lo estándar es 1000 mm pero se puede fabricar
              a pedido largos especiales.
            </li>
          </ul>
          <p class="my-4">Tubos o bujes: Consultar.</p>
          <p class="my-4">Planchas</p>
          <ul>
            <li><span>Espesores:</span> 6-8-10-12-15-20-25-30-40-50-60-Etc.</li>
            <li><span>Anchos:</span> 350-400-450-500-600 mm.</li>
            <li>
              <span>Largos:</span> lo estándar es 2000 mm pero se puede fabricar
              a pedido largos especiales.
            </li>
          </ul>
        </ul>
      </b-tab-item>
    </b-tabs>

    <div
      class="columns mt-5 is-justify-content-space-around is-multiline is-centered"
    >
      <CardCategory
        v-for="(card, index) in banners.carousels"
        :key="index"
        :content="card.content"
        :title="card.title"
        :url="card.image"
        class="column is-3 is-four-fifths-mobile m-auto"
      />
    </div>

    <!-- <div class="buttons">
      <div class="block">
        <b-button type="is-danger" icon-left="download" @click="downloadFile">
          Descargar características Poliamida 6 PA6
        </b-button>
      </div>
    </div> -->

  </section>
  <Contact />
</div>
</template>

<script>
import CardCategory from "@/components/CardCategory.vue";
import bannersData from "../config/polyamide.json";
import Contact from "./ContactSection.vue";

export default {
  components: {
    CardCategory,
    Contact
  },
  data() {
    return {
      activeTab: 0,
      isIndex: false,
      banners: [],
    };
  },
  methods: {
    downloadFile() {
      const fileUrl = "/files/poliamida.pdf";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "poliamida.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  created() {
    console.log(bannersData);
    this.banners = bannersData;
  },
};
</script>