<template>
  <div>
    <section class="container category">
      <b-button
        class="my-3"
        type="is-primary"
        size="is-small"
        icon-left="arrow-left"
        @click="$router.go(-1)">
        Atrás
      </b-button>
      <article>
        <h1 class="title">Resina Acetal POM</h1>
        <p>
          Este copolímero de acetal es un termoplástico con estructura altamente
          cristalina que se distingue por su alta resistencia mecánica y su buena
          resistencia a la temperatura. Es un material apropiado para ser
          mecanizado en tornos automáticos y está especialmente recomendado para
          su uso en piezas mecánicas de precisión.
        </p>
        <br />
        <p>
          Se aplica usualmente en rodillos y jaulas de rodamientos, guarniciones,
          ruedas dentadas, cuerpos de bombas, tornillos, componentes en la técnica
          de la mecánica de precisión y textil, o soportes de líneas de barnizado,
          etc.
        </p>
        <br />
      </article>
      <b-tabs class="mt-5" v-model="activeTab">
        <b-tab-item label="Descripción">
          <ul class="custom-ul">
            <li>Es duro, rígido y con un buen coeficiente de deslizamiento.</li>
            <li>
              Posee gran estabilidad dimensional ya que su absorción de agua es
              menor que las poliamidas.
            </li>
            <li>Bajo coeficiente de fricción.</li>
            <li>Resistencia a la abrasión.</li>
            <li>Fácil mecanización.</li>
            <li>Se suelda por fricción y contacto.</li>
            <li>
              Básicamente no hay susceptibilidad alguna a fisuras de esfuerzos.
            </li>
            <li>Alta termoestabilidad.</li>
            <li>
              Resistente a la hidrólisis, a las bases fuertes y a la degradación
              por oxidación térmica.
            </li>
            <li>
              Su resistencia a los rayos UV, así como ácidos y álcalis es débil.
            </li>
          </ul>
        </b-tab-item>

        <b-tab-item label="Aplicaciones">
          <ul class="custom-ul">
            <li>Rodamientos.</li>
            <li>Ruedas dentadas.</li>
            <li>Componentes industriales.</li>
          </ul>
        </b-tab-item>

        <b-tab-item label="Medidas">
          <ul class="custom-ul">
              <p class="my-4">Barras redondas:</p>
                  <ul class="custom-ul">
                      <li>Desde Ø25 mm a 150 mm x 1000 mm de largo.</li>
                      <li><span>Largo:</span> lo estándar es 1000 mm pero se puede fabricar a pedido largos especiales.</li>
                  </ul>
              
              <p class="my-4">Barras cuadradas:</p>
              <ul class="custom-ul">
                  <li>Desde 20 a 80  mm x 1000 mm de largo.</li>
                  <li><span>Largo:</span> lo estándar es 1000 mm pero se puede fabricar a pedido largos especiales.</li>
              </ul>
              <p class="my-4">Tubos o bujes: Consultar.</p>
              <p class="my-4">Planchas</p>
              <ul class="custom-ul">
                  <li><span>Espesores:</span> 6-8-10-12-15-20-25-30-40-50-60-Etc.</li>
                  <li><span>Anchos:</span> 350-400-450-500-600 mm.</li>
                  <li><span>Largos:</span> lo estándar es 2000 mm pero se puede fabricar a pedido largos especiales.</li>
              </ul>
          </ul>
        </b-tab-item>
      </b-tabs>

      <div class="columns mt-5 is-justify-content-space-around is-multiline is-centered">
        <CardCategory v-for="(card, index) in banners.carousels" 
          :key="index" 
          :content="card.content" 
          :title="card.title" 
          :url="card.image" 
          class="column is-3 is-four-fifths-mobile m-auto"/>
      </div>

      <!-- <div class="buttons">
        <div class="block">
          <b-button
            type="is-danger"
            icon-left="download"
            @click="downloadFile"
          >
            Descargar características Resina Acetal POM
          </b-button>
        </div>
      </div> -->
    </section>
    <Contact />
  </div>
</template>

<script>
import CardCategory from '@/components/CardCategory.vue';
import bannersData from '../config/resin.json';
import Contact from "./ContactSection.vue";

export default {
  components: {
    CardCategory,
    Contact
  },
  data() {
    return {
      activeTab: 0,
      isIndex: false,
      banners: []
    };
  },
  methods: {
    downloadFile() {
      const fileUrl = '/files/resina.pdf';
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', 'resina.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Limpieza del DOM
    }
  },
  created() {
    this.banners = bannersData;
  }
};
</script>