<template>
  <b-carousel
    :indicator-inside="true"
    :autoplay="true"
    :pause-hover="true"
    :pause-info="false"
    :indicator="true"
    :progress="false"
    :arrow="false"
    :arrow-hover="false"
  >
    <b-carousel-item v-for="(item, i) in carousels" :key="i">
        <section class="carousel-section hero is-medium" :style="getBackgroundImage(item.image)" @click="goTo">
            <div class="hero-body has-text-centered">
            </div>
        </section>
    </b-carousel-item>
  </b-carousel>
</template>

<script>
export default {
  props: {
    carousels: { type: Array, required: true }
  },
  methods: {
     getBackgroundImage(image) {
      try {
        return `background-image: url(${require('@/assets/img/' + image)})`;
      } catch (error) {
        console.error('Error cargando la imagen:', error);
        return '';
      }
    },
    goTo(){
      console.log('go to category')
    }
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  width: 100%;
}
.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
}

.carousel-section{
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;

  @media (max-width: 768px) {
    background-size: cover;
    width: 100%;
    height: 24vh;
  }

  &:hover{
    cursor: pointer;
  }
}

</style>

<style lang="scss">

.indicator-style{
  background: $white !important;
  border-color: $orange !important;
}

.indicator-item.is-active{
  .indicator-style{
    background: $orange !important;
  }
}
</style>