<template>
  <div>
    <section class="container category">
      <b-button
        class="my-3"
        type="is-primary"
        size="is-small"
        icon-left="arrow-left"
        @click="$router.go(-1)"
      >
        Atrás
      </b-button>
      <article>
        <h1 class="title">Mecanizados</h1>
        <article class="mechanized-text">
          <p>
            En TGPLAST contamos con el servicio de entregar la pieza ya terminada
            para ser utilizada por el cliente.
          </p>
          <p>
            Acompañamos los proyectos de nuestros clientes desde el inicio de un
            desarrollo hasta la fabricación en serie de las piezas.
          </p>
          <p>
            Para la fabricación de las piezas podemos partir de una
            <span>IDEA</span> de un cliente donde mediante el asesoramiento y
            ajustes en el dibujo, obtenemos la de pieza de muestra para luego
            terminar en la pieza final y funcional.
          </p>
          <p>
            Podemos partir de <span>PLANOS</span> ya confeccionados y aprobados de
            nuestros clientes y solo nos dedicamos a la manufactura de las piezas.
          </p>
          <p>
            O podemos partir de una <span>MUESTRA</span> donde identificamos el
            tipo de polímero y realizamos el relevamiento para la posterior
            fabricación de las piezas.
          </p>
          <p>
            Nuestra intención es que nos consulten por cualquier inquietud y
            realizaremos nuestro mejor asesoramiento.
          </p>
          <p>
            Nuestra estructura y personal capacitado nos permite realizar todo
            tipo de productos en 2D o 3D ya sean en pequeñas o grandes cantidades.
          </p>
        </article>
      </article>
      <!-- <b-tabs class="mt-5" v-model="activeTab">
        <b-tab-item label="Descripción">
          <ul class="custom-ul">
            <li>Es duro, rígido y con un buen coeficiente de deslizamiento.</li>
            <li>
              Posee gran estabilidad dimensional ya que su absorción de agua es
              menor que las poliamidas.
            </li>
            <li>Bajo coeficiente de fricción.</li>
            <li>Resistencia a la abrasión.</li>
            <li>Fácil mecanización.</li>
            <li>Se suelda por fricción y contacto.</li>
            <li>
              Básicamente no hay susceptibilidad alguna a fisuras de esfuerzos.
            </li>
            <li>Alta termoestabilidad.</li>
            <li>
              Resistente a la hidrólisis, a las bases fuertes y a la degradación
              por oxidación térmica.
            </li>
            <li>
              Su resistencia a los rayos UV, así como ácidos y álcalis es débil.
            </li>
          </ul>
        </b-tab-item>
      </b-tabs> -->
      <div
        class="columns mt-5 is-justify-content-space-around is-multiline is-centered"
      >
        <CardCategory
          v-for="(card, index) in banners.carousels"
          :key="index"
          :content="card.content"
          :title="card.title"
          :url="card.image"
          class="column is-3 is-four-fifths-mobile mx-1 mb-5"
        />
      </div>
    </section>
    <Contact />
  </div>
</template>

<script>
import CardCategory from "@/components/CardCategory.vue";
import bannersData from "../config/mechanized.json";
import Contact from "./ContactSection.vue";

export default {
  components: {
    CardCategory,
    Contact
  },
  data() {
    return {
      activeTab: 0,
      isIndex: false,
      banners: [],
    };
  },
  created() {
    this.banners = bannersData;
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .column{
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.mechanized-text {
  p {
    span {
      color: $orange;
      font-weight: 800;
    }
  }
}
</style>