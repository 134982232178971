<template>
  <div>
    <section class="container category">
      <b-button
        class="my-3"
        type="is-primary"
        size="is-small"
        icon-left="arrow-left"
        @click="$router.go(-1)"
      >
        Atrás
      </b-button>
      <article>
        <h1 class="title">Polietileno APM</h1>
        <h2 class="subtitle">Polietileno alto peso molecular (PE-APM)</h2>
        <p>
          Es uno de los materiales más utilizado en las industrias, de absorción
          nula de humedad, una gran resistencia química, muy bajo coeficiente de
          rozamiento y muy liviano con su peso especifico menor a 1. Material
          aprobado por SENASA y FDA para estar en contacto directo con los
          alimentos.
        </p>
        <br />
        <p>
          Está indicado para aplicaciones como guías de base para deslizamiento de
          cadena y de correas, banda de desgaste, mesas y tablas de corte,
          estructuras de apoyo, etc.
        </p>
      </article>
      <b-tabs class="mt-5" v-model="activeTab">
        <b-tab-item label="Características">
          <ul class="custom-ul">
            <li>Rango de temperatura de trabajo -100ºC +80ºC.</li>
            <li>Buena resistencia al impacto incluso a bajas temperaturas.</li>
            <li>Bajo coeficiente de fricción.</li>
            <li>Resistencia a la abrasión.</li>
            <li>Fisiológicamente inerte.</li>
            <li>Liviano e irrompible.</li>
            <li>Resistente a las bajas temperaturas.</li>
            <li>Aprobado por SENASA y FDA para contacto con alimentos.</li>
            <li>
              Resistente a agentes químicos corrosivos como ser ácido sulfúrico,
              etc.
            </li>
          </ul>
        </b-tab-item>
        <b-tab-item label="Aplicaciones">
          <ul class="custom-ul">
            <li>Guías, Rodillos y Bujes.</li>
            <li>Mesas y tablas de cortes de productos alimenticios .</li>
            <li>Mesas y tablas de cortes de productos alimenticios .</li>
            <li>Estrellas distribuidoras.</li>
            <li>
              Placas para el recubrimiento de defensas o escudos en los muelles o
              puertos.
            </li>
            <li>Recubrimientos de tolvas y camiones volcadores.</li>
            <li>Barredores de cadenas redler.</li>
          </ul>
        </b-tab-item>

        <b-tab-item label="Aplicaciones por rubro">
          <ul class="custom-ul">
            <li>Guías de cadera en cintas transportadoras.</li>
            <li>
              Estrellas de distribución y sinfines en embotelladoras y
              fraccionadoras.
            </li>
            <li>
              Tablas de corte y desposte en industrias frigoríficas y locales
              gastronómicos.
            </li>
            <li>
              Tacos de apoyos, guías y patines en racks de transporte de piezas
              automotrices.
            </li>
            <li>
              Recubrimientos de semirremolques y camiones volcadores, mixer y
              comederos agrícolas.
            </li>
            <li>Fabricación de tanques a bateas a medida.</li>
            <li>
              Piezas plásticas utilizadas en maquinaria agrícola (ej. patines de
              plataformas).
            </li>
            <li>Paletas redler en transportadores de granos.</li>
          </ul>
        </b-tab-item>

        <b-tab-item label="Medidas">
          <ul class="custom-ul">
            <p class="my-4">Barras redondas:</p>
            <ul class="custom-ul">
              <li>Desde Ø25 mm a 150 mm x 1000 mm de largo.</li>
              <li>
                <span>Largo:</span> lo estándar es 1000 mm pero se puede fabricar
                a pedido largos especiales.
              </li>
            </ul>

            <p class="my-4">Barras cuadradas:</p>
            <ul class="custom-ul">
              <li>Desde 20 a 80 mm x 1000 mm de largo.</li>
              <li>
                <span>Largo:</span> lo estándar es 1000 mm pero se puede fabricar
                a pedido largos especiales.
              </li>
            </ul>
            <p class="my-4">Tubos o bujes: Consultar.</p>
            <p class="my-4">Planchas</p>
            <ul class="custom-ul">
              <li><span>Espesores:</span> 6-8-10-12-15-20-25-30-40-50-60-Etc.</li>
              <li><span>Anchos:</span> 350-400-450-500-600 mm.</li>
              <li>
                <span>Largos:</span> lo estándar es 2000 mm pero se puede fabricar
                a pedido largos especiales.
              </li>
            </ul>
          </ul>
        </b-tab-item>
      </b-tabs>

      <div
        class="columns mt-5 is-justify-content-space-around is-multiline is-centered"
      >
        <CardCategory
          v-for="(card, index) in banners.carousels"
          :key="index"
          :content="card.content"
          :title="card.title"
          :url="card.image"
          class="column is-3 is-four-fifths-mobile m-auto"
        />
      </div>
      <!-- <div class="buttons">
        <div class="block">
          <b-button type="is-danger" icon-left="download" @click="downloadFile">
            Descargar características Polietileno APM
          </b-button>
        </div>
      </div> -->
    </section>
    <Contact/>
  </div>
</template>

<script>
import CardCategory from "@/components/CardCategory.vue";
import bannersData from "../config/polyethylene.json";
import Contact from "./ContactSection.vue";

export default {
  components: {
    CardCategory,
    Contact
  },
  data() {
    return {
      activeTab: 0,
      isIndex: false,
      banners: [],
    };
  },
  methods: {
    downloadFile() {
      const fileUrl = "/files/polietileno.pdf";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "polietileno.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Limpieza del DOM
    },
  },
  created() {
    this.banners = bannersData;
  },
};
</script>