<template>
  <div class="main" id="main">
    <CarouselHomeComponentVue :carousels="banners.carousels"/>
    <div class="mt-5">
      <section class="container services" id="services">
        <h2 class="title">Categorías</h2>
        <div class="mt-5 mb-5 columns is-multiline is-centered">
          <CardComponentVue 
            class="column is-3 is-four-fifths-mobile m-auto card-services" 
            title="Polietileno APM" 
            subtitle="Resistente al desgaste y corrosión, ideal para aplicaciones con baja fricción y alta durabilidad."
            url="polyethylene-APM"
          />
          <CardComponentVue 
            class="column is-3 is-four-fifths-mobile m-auto card-services" 
            title="Poliamida 6PA6" 
            subtitle="Fuerte y resistente al impacto, excelente para piezas mecánicas por su estabilidad y durabilidad."
            url="polyamide"
          />
          <CardComponentVue 
            class="column is-3 is-four-fifths-mobile m-auto card-services" 
            title="Polipropileno PP" 
            subtitle="Ligero y resistente a químicos, ampliamente usado por su capacidad de soportar tensiones y agua."
            url="polypropylene-PP"
          />
          <CardComponentVue 
            class="column is-3 is-four-fifths-mobile m-auto card-services" 
            title="Recina Acetal POM" 
            subtitle="Material resistente al desgaste y fricción, perfecto para piezas de precisión en aplicaciones industriales."
            url="acetal-resin"
          />
          <CardComponentVue 
            class="column is-3 is-four-fifths-mobile m-auto card-services" 
            title="Mecanizados" 
            subtitle="Piezas personalizadas de alta precisión para diversas aplicaciones industriales."
            url="mechanized"
          />
          <CardComponentVue 
            class="column is-3 is-four-fifths-mobile m-auto card-services" 
            title="Recubrimientos plásticos" 
            subtitle="Protección mejorada contra corrosión y desgaste en superficies industriales y comerciales."
            url="plastic-coating"
          />
        </div>

      </section>

      <section class="about" id="about">
        <h2 class="title">Sobre nosotros</h2>
        <div class="container">
          <div class="my-5">
            <p>
              En <b>TGPLAST</b>, somos especialistas en la extrusión de plásticos industriales.
              Fabricamos planchas, barras y tubos en una variedad de materiales innovadores, diseñados para reemplazar metales, 
              maderas y otros materiales tradicionales en diversas aplicaciones.
            </p>

            <p>Con un enfoque en la calidad y la personalización, ofrecemos además la creación de piezas a medida, partiendo de ideas, muestras o planos de nuestros clientes.</p>

            <div>
              <p>Nuestras soluciones plásticas están presentes en industrias como:</p>
              <div class="columns pt-5 mt-5 mb-5 is-multiline is-centered">
                <CardComponentVue 
                  class="column is-3 is-four-fifths-mobile m-auto card-about"
                  icon="tractor" 
                  title="Agricultura" 
                  subtitle="Componentes como bujes autolubricados y revestimientos duraderos." 
                  :footer="false"
                  :clickeable="false"
                />
                <CardComponentVue 
                  class="column is-3 is-four-fifths-mobile m-auto card-about"
                  icon="shoe-prints"
                  title="Calzado" 
                  subtitle="Pilones especializados para el troquelado." 
                  :footer="false"
                  :clickeable="false"
                />
                <CardComponentVue 
                  class="column is-3 is-four-fifths-mobile m-auto card-about" 
                  icon="anchor" 
                  title="Puertos" 
                  subtitle="Recubrimiento de defensas y escudos." 
                  :footer="false"
                  :clickeable="false"
                />
                <CardComponentVue 
                  class="column is-3 is-four-fifths-mobile m-auto card-about" 
                  icon="car" 
                  title="Automotriz" 
                  subtitle="Protección para racks y sistemas de almacenamiento." 
                  :footer="false"
                  :clickeable="false"
                />
                <CardComponentVue 
                  class="column is-3 is-four-fifths-mobile m-auto card-about" 
                  icon="utensil-spoon" 
                  title="Alimentación" 
                  subtitle="Plásticos aprobados por SENASA para un entorno limpio y seguro." 
                  :footer="false" 
                  :clickeable="false"
                />
              </div>
            </div>
            <p>Cada uno de nuestros materiales es adaptable a las necesidades específicas de tu proyecto, con opciones de colores personalizados, protección UV y propiedades antiestáticas. Nuestros productos destacan por su resistencia, ligereza y bajo coeficiente de fricción, además de ser altamente duraderos en entornos exigentes.</p>
            <p>En <b>TGPLAST</b>, combinamos innovación con atención al detalle para ofrecer soluciones plásticas que potencien el rendimiento de tu industria.</p>
          </div>
        </div>
      </section>

    </div>
    <Contact />
  </div>
</template>

<script>
import CarouselHomeComponentVue from '@/components/CarouselHomeComponent.vue';
import CardComponentVue from '@/components/CardComponent.vue';
import Contact from "./ContactSection.vue"
import bannersData from '../config/plastic-banners.json';

export default {
  components: {
    CarouselHomeComponentVue,
    CardComponentVue,
    Contact
  },
  data() {
    return {
      banners: []
    }
  },
  created() {
    this.banners = bannersData;
  }
};
</script>

<style lang="scss" scoped>
.columns {
  gap: 2rem;
}

.title{
  text-align: center;
  font-family: oswald-bold;
  color: $orange;
}

section{
  padding-top: 2em;
  padding-bottom: 5em !important;
}

.about{
  background-color: $black;
  font-family: quattrocento-bold;

  .container{
    width: 95%;
    margin: auto;
  }
  p{
    color: $white;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 1.5rem;
  }
  
  p {
    font-size: 1rem;
  }

  .card-about, .card-services{
    margin-bottom: 25px !important;
  }
}
</style>
