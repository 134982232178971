<template>
  <div id="app">
    <b-navbar
    fixed-top>
        <template #brand>
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <img
                    src="@/assets/logo.png"
                    alt="Lightweight UI components for Vue.js based on Bulma"
                >
            </b-navbar-item>
        </template>
        <template #start>
            <b-navbar-item v-if="currentPageName === 'home'" v-scroll-to="'#main'">
              Inicio
            </b-navbar-item>

            <b-navbar-item v-else href="/">
              Inicio
            </b-navbar-item>

            <b-navbar-item v-scroll-to="'#about'" v-if="currentPageName === 'home'">
                Sobre nosotros
            </b-navbar-item>
            <b-navbar-item v-scroll-to="'#contact'" v-if="currentPageName === 'home'">
                Contacto
            </b-navbar-item>
            <b-navbar-dropdown label="Categorías">
                <b-navbar-item href="/polyethylene-APM">
                    Polietileno APM
                </b-navbar-item>
                <b-navbar-item href="/polyamide">
                    Poliamida 6PA6
                </b-navbar-item>
                <b-navbar-item href="/polypropylene-PP">
                    Polipropileno PP
                </b-navbar-item>
                <b-navbar-item href="/acetal-resin">
                    Recina Acetal POM
                </b-navbar-item>
                <b-navbar-item href="/mechanized">
                    Mecanizados
                </b-navbar-item>
                <b-navbar-item href="/plastic-coating">
                    Recubrimientos plásticos
                </b-navbar-item>
            </b-navbar-dropdown>
        </template>
    </b-navbar>
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isIndex: true
    }
  },
  computed: {
    currentPageName() {
      return this.$route.name;
    }
  }
}
</script>

<style lang="scss">
html{
  overflow-x: hidden;
}
.navbar-item{
  font-family: oswald-regular;
}
a.navbar-item:focus, 
a.navbar-item:focus-within,
a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active {
  color: $orange !important;
  background-color: transparent !important;
}

.navbar-link:not(.is-arrowless)::after{
  border-color: $orange !important;
}

@media (max-width: 900px) {
  .category{
    width: 96% !important;
  }
}

.button.is-primary{
  background-color: $orange !important;
}

.category{
  font-family: quattrocento-bold;
  margin-bottom: 5em !important;
  .title{
    text-align: center !important;
    font-family: oswald-bold !important;
    color: #ef9c00 !important;
  }

  .subtitle{
    text-align: center !important;
    font-size: 20px;
    font-family: quattrocento-bold;
  }
  .b-tabs{
    border-bottom-color: $orange !important;
    margin-top: 20px;
    font-family: quattrocento-bold;
     
    p{
      span{
        color: $orange !important;
        font-weight: 800;
      }
    }
    .custom-ul{
      li{
        margin-top: 10px;
  
        &::before{
          content: "\f058";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          color: $orange;
          font-size: 16px;
          margin-right: 10px;
        }
      }
    }
    li.is-active a{
      border-bottom-color: $orange !important;
      background-color: $orange !important;
      color: $white !important;
    }
  }

  .buttons{
    margin-top: 5em;
  }
}
</style>