<template>
  <div 
    class="card" 
    data-aos="flip-left" 
    @click="clickeable ? redirect() : null" 
    :class="{ 'no-hover': !clickeable }" 
  >
    <div class="card-content">
      <div class="content">
        <b-icon class="icon" v-if="icon" :icon="icon" size="is-medium"></b-icon>
        <p class="title is-5">{{ title }}</p>
        <p class="subtitle is-6 mt-4">{{ subtitle }}</p>
      </div>
    </div>
    <footer class="card-footer" v-if="footer && url">
      <a :href="url">Ver más</a>
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    icon: { type: String },
    title: { type: String },
    subtitle: { type: String },
    footer: { type: Boolean, default: true },
    url: { type: String },
    clickeable: { type: Boolean, default: true }
  },
  methods: {
    redirect() {
      if (this.url) window.location = this.url;
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  text-align: center;
  transition: transform 0.5s ease;

  &:hover {
    transform: scale(1.03);
    cursor: pointer;
  }

  &.no-hover {
    cursor: default !important;
    &:hover {
      transform: none !important;
    }
  }

  .content {
    height: 130px;
    min-height: 130px;
    .title {
      font-family: oswald-bold;
      color: $black;
    }

    .subtitle{
      color: $black !important;
    }
    
    font-family: quattrocento-bold;

    .icon {
      color: $orange;
    }
  }

  .card-footer {
    display: flex;
    justify-content: center;
    min-height: 20px;
    max-height: 20px;

    a {
      display: flex;
      color: $orange !important;
      &:hover {
        color: $orange-dark;
      }
    }
  }
}
</style>
