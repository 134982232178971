import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PolyethyleneAPMView from '../views/PolyethyleneAPMView.vue'
import PolypropylenePPView from '../views/PolypropylenePPView.vue'
import MechanizedView from '../views/MechanizedView.vue'
import PlasticCoatingView from '../views/PlasticCoatingView.vue'
import AcetalResinView from '../views/AcetalResinView.vue'
import PolyamideView from '../views/PolyamideView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/polyethylene-APM',
    name: 'polAPM',
    component: PolyethyleneAPMView
  },
  {
    path: '/polyamide',
    name: 'polyamide',
    component: PolyamideView
  },
  {
    path: '/polypropylene-PP',
    name: 'polPP',
    component: PolypropylenePPView
  },
  {
    path: '/acetal-resin',
    name: 'acetalResin',
    component: AcetalResinView
  },
  {
    path: '/mechanized',
    name: 'mechanized',
    component: MechanizedView
  },
  {
    path: '/plastic-coating',
    name: 'plasticCoating',
    component: PlasticCoatingView
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
